body {
  margin: 0;
  background-image: url('https://res.cloudinary.com/dg2t0sykk/image/upload/v1734309425/R1-07284-0009_coyi1f.jpg');
  background-color: black;
  background-size: cover;
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  font-family: Arial, sans-serif; /* Optional: Sets a global font style */
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
